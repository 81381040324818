// ----------------------------------------------------------------------

const ROOTS = {
  DASHBOARD: '/',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `/login`,
      otp: `/otp`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}dashboard`,
    analytics: '/analytics',
    customer: '/customer',
    commission: '/commission',
    reconciliation: '/reconciliation',
    payment: '/payment',
  },
};
