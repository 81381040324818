const STORAGE_KEY = 'accessToken';
const USER_INFORMATION = 'user_information';

const OPTIONS_FETCH = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  revalidateOnMount: true,
};

const ERROR_CODE = {
  INVALID_AUTH_TICKET: 10001,
  INVALID_TWO_FACTOR_CODE: 10002,
  INVALID_OTP_CODE: 10003,
  INVALID_RESET_PASSWORD_TOKEN: 10004,
  INSUFFICIENT_BALANCE: 10005,
  INSUFFICIENT_PROFILE_BALANCE: 10006,
  PLATFORM_ACCOUNT_EXIST: 10007,
  WORKSPACE_USER_EXIST: 10008,
  MEMBERS_EXISTS_CAN_NOT_DELETE: 10009,
  NOT_PURCHASED_PACKAGE: 10010,
  INVITE_REQUEST_EXIST: 10011,
  INVITE_LINK_EXPIRED: 10012,
  PASSWORD_ALREADY_EXISTS: 10013,
  PASSWORD_INCORRECT: 10014,
  SELF_INVITE: 10015,
  NOT_PERMISSION: 403,
};

export { STORAGE_KEY, OPTIONS_FETCH, USER_INFORMATION, ERROR_CODE };
