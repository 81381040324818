import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';
import { getStorage, clearSession, clearStorage } from 'src/hooks/use-local-storage';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.request.use((config) => {
  const token = getStorage('accessToken');
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error?.response?.status === 401) {
      clearStorage();
      clearSession();
      window.location.href = paths.auth.jwt.login;
      return false;
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, {
    baseURL: HOST_API,
  });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: '/api/v1/me',
    login: '/api/v1/auth/login',
    google_login: '/api/v1/auth/login/google',
    loginOtp: '/api/v1/auth/otp',
    logout: '/api/v1/auth/logout',
    resend_otp: '/api/v1/auth/otp/resend',
  },
  customer: {
    list: '/api/v1/affiliate/customers',
  },
  commission: {
    list: '/api/v1/affiliate/commissions',
  },
  reconciliation: {
    list: '/api/v1/affiliate/reconciliations',
    detail: (reconciliationId) => `/api/v1/affiliate/reconciliations/${reconciliationId}`,
  },
  earning: {
    summary: '/api/v1/affiliate/earnings-summary',
    monthly: '/api/v1/affiliate/earnings-monthly-chart',
    current_week: '/api/v1/affiliate/earnings-current-week',
    current_month: '/api/v1/affiliate/earnings-current-month',
  },
  payment: {
    list: 'api/v1/payment-info',
    create: 'api/v1/payment-info',
    update: (idBank) => `/api/v1/payment-info/${idBank}`,
    delete: (idBank) => `/api/v1/payment-info/${idBank}`,
    updatePaymentInfo: (idPayment) => `api/v1/affiliate/reconciliations/${idPayment}/payment-info`,
  },
  analytics: {
    line_chart: '/api/v1/affiliate/analytics-chart',
    summary: '/api/v1/affiliate/analytics-summary',
  },
};
