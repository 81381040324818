import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const OTPPage = lazy(() => import('src/pages/auth/jwt/otp'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: '',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <AuthModernCompactLayout>
            <Outlet />
          </AuthModernCompactLayout>
        </Suspense>
      </GuestGuard>
    ),
    children: [
      {
        path: '/login',
        element: <JwtLoginPage />,
      },
      {
        path: '/otp',
        element: <OTPPage />,
      },
    ],
  },
];
