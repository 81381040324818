import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { clearStorage, getStorage, setStorage } from 'src/hooks/use-local-storage';
import axiosInstance, { endpoints } from 'src/utils/axios';
// utils
import { STORAGE_KEY } from 'src/utils/constance';
//
import axios from 'axios';
import { HOST_API } from 'src/config-global';
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = getStorage(STORAGE_KEY);

      if (accessToken) {
        const response = await axiosInstance.get(endpoints.auth.me);

        const user = response.data;

        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...user,
              accessToken,
            },
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (username, password) => {
    const data = {
      username,
      password,
    };
     await axios.post(HOST_API + endpoints.auth.login, data);
  }, []);

  // LOGIN OTP
  const loginOtp = useCallback(async (code, ticket) => {
    const dataPayload = {
      code,
      ticket,
    };

    const response = await axios.post(HOST_API + endpoints.auth.loginOtp, dataPayload);
    const { token, user, device_hash } = response.data;

    setStorage(STORAGE_KEY, `Token ${token}`);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...user,
          accessToken: token,
          device_hash,
        },
      },
    });
  }, []);

  // LOGIN WITH GOOGLE
  const loginWithGoogle = useCallback(async ({ access_token, ref_code }) => {
    const payload = {
      access_token,
      ref_code,
    };
    const response = await axios.post(HOST_API + endpoints.auth.google_login, payload);
    const { token, user, device_hash } = response.data;

    setStorage(STORAGE_KEY, `Token ${token}`);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...user,
          accessToken: token,
          device_hash,
        },
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    try {
      await axiosInstance.post(endpoints.auth.logout);

      const settings = window.localStorage.getItem('settings');
      clearStorage();
      if (settings !== null) {
        window.localStorage.setItem('settings', settings);
      }
      dispatch({
        type: 'LOGOUT',
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      loginWithGoogle,
      loginOtp,
      logout,
    }),
    [login, loginOtp, loginWithGoogle, logout, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
