import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  dashboard: icon('ic_dashboard'),
  analytics: icon('ic_analytics'),
  customer: icon('ic_customer'),
  commission: icon('ic_invoice'),
  order: icon('ic_order'),
  reconciliation: icon('ic_exchange'),
  payment: icon('ic_banking'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      {
        subheader: 'overview v1.0.0',
        items: [
          { title: 'Tổng quan', path: paths.dashboard.root, icon: ICONS.dashboard },
          { title: 'Analytics', path: paths.dashboard.analytics, icon: ICONS.analytics },
          { title: 'Khách hàng', path: paths.dashboard.customer, icon: ICONS.customer },
          { title: 'Hoa hồng', path: paths.dashboard.commission, icon: ICONS.order },
          {
            title: 'Đối soát',
            path: paths.dashboard.reconciliation,
            icon: ICONS.reconciliation,
          },
          { title: 'Thanh toán', path: paths.dashboard.payment, icon: ICONS.payment },
        ],
      },
    ],
    []
  );

  return data;
}
