import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import MainLayout from 'src/layouts/homepage';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const DashboardPage = lazy(() => import('src/pages/dashboard/dashboard-page'));
const AnalyticsPage = lazy(() => import('src/pages/analytics/analytics-page'));
const CustomerPage = lazy(() => import('src/pages/customer/customer-page'));
const CommissionPage = lazy(() => import('src/pages/commission/commission-page'));
const PaymentPage = lazy(() => import('src/pages/payment/payment-page'));
const ReconciliationPage = lazy(() => import('src/pages/reconciliation/reconciliation-page'));

// ----------------------------------------------------------------------

export const homepageRoutes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'dashboard', element: <DashboardPage /> },
      { path: 'analytics', element: <AnalyticsPage /> },
      { path: 'customer', element: <CustomerPage /> },
      { path: 'commission', element: <CommissionPage /> },
      { path: 'reconciliation', element: <ReconciliationPage /> },
      { path: 'payment', element: <PaymentPage /> },
    ],
  },
];
